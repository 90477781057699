import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {Wrapper} from "./pages/wrapper";
import {SuccessPage} from "./pages/SuccessPage";
import {FailPage} from "./pages/FailPage";
import {I18nProvider} from './i18n/use-i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Wrapper/>}>
                        <Route index element={<App/>}/>
                        <Route path={'success'} element={<SuccessPage/>}/>
                        <Route path={'fail'} element={<FailPage/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </I18nProvider>
    </React.StrictMode>
);

reportWebVitals();

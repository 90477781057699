import {createContext, useContext, useState, useEffect, useMemo, useCallback} from "react";
import {translate as translateUtil} from "./translate";
import {isUserFrom} from "../util/get-user-country";
import Project from "../constants/project";

const defaultLanguage = {
    [Project.ANONRUBOT]: 'ru',
    [Project.CHATBOT]: 'en',
    [Project.CHATBOT_V2]: 'en',
}

const supportedLanguages = {
    [Project.ANONRUBOT]: ['ru'],
    [Project.CHATBOT]: ['en', 'id'],
    [Project.CHATBOT_V2]: ['en', 'id'],
};

const I18nContext = createContext(null);

const determineUserLanguage = async () => {
    const isChatBot = process.env.REACT_APP_PROJECT === Project.CHATBOT;
    if (isChatBot && (await isUserFrom('ID'))) {
        return 'id';
    }
    return defaultLanguage[process.env.REACT_APP_PROJECT] ?? 'en';
};

const normalizeLanguage = (language) => {
    const project = process.env.REACT_APP_PROJECT;
    // Should never happen
    if (!project) return 'en';

    const projectLanguages = supportedLanguages[project];
    // Should never happen
    if (!projectLanguages) return defaultLanguage[project] ?? 'en';
    
    const isSupportedLanguage = projectLanguages.includes(language);
    if (!isSupportedLanguage) {
        return defaultLanguage[project];
    }
    return language;
}

export const I18nProvider = ({ children }) => {
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const isChatBot = process.env.REACT_APP_PROJECT === Project.CHATBOT;
    const initialLanguage = defaultLanguage[process.env.REACT_APP_PROJECT] ?? 'en';
    const [language, setLanguage] = useState(initialLanguage);

    useEffect(() => {
        determineUserLanguage().then(setLanguage);
    }, []);

    const effectiveLanguage = useMemo(() => {
        if (isChatBot && searchParams.get('discount') === 'new_users_id') {
            return 'id';
        }
        return normalizeLanguage(language);
    }, [language, searchParams, isChatBot]);

    const translate = useCallback((key, params) => {
        return translateUtil(effectiveLanguage, key, params);
    }, [effectiveLanguage]);

    return (
        <I18nContext.Provider value={{ language: effectiveLanguage, setLanguage, translate }}>
            {children}
        </I18nContext.Provider>
    );
};

export const useI18n = () => {
    const context = useContext(I18nContext);
    if (!context) {
        throw new Error("useI18n must be used within an I18nProvider");
    }
    return context;
};
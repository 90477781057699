import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useI18n } from "../i18n/use-i18n";
import UserIdInput from '../components/UserIdInput';
import { Button } from "./Button";
import styles from './ChangeUserIdModal.module.css';
import {
    AccountValidationState,
} from "../constants/accountValidationStates";
import {validateAccount} from "../util/validate-account";
import {useValidationState} from "../util/use-validation-state";

const ChangeUserIdModal = forwardRef((props, ref) => {
  const {translate} = useI18n();
  const {
    setUserId,
    userId,
} = props;
  const dialogRef = useRef(null);
  const inputRef = useRef(null);
  const [localUserId, setLocalUserId] = useState(userId);
  const [validationState, setAccountValidationState] = useValidationState(localUserId);


  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);

  useEffect(() => {
    validateAccount(userId)
            .then((res) => {
                if (res) {
                  setAccountValidationState(AccountValidationState.CORRECT_ID);
                } else {
                    setAccountValidationState(AccountValidationState.WRONG_ID);
                }
            })
            .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
  }, [userId, setAccountValidationState]);

  useImperativeHandle(ref, () => ({
    showModal: () => {
      dialogRef.current?.showModal();
      inputRef.current?.focus();
    },
  }));

  const handleBackdropClick = (event) => {
    event.preventDefault();

    const {target, currentTarget} = event;
    const isClickedOnBackdrop = target === currentTarget;

    if (isClickedOnBackdrop) {
      dialogRef.current.close();
      setLocalUserId(userId);
    }
}

  const handleSave = useCallback(() => {
    if (validationState === AccountValidationState.CORRECT_ID) {
      setUserId(localUserId);
      dialogRef.current?.close();
      setLocalUserId(userId);
    }
  }, [validationState, setUserId, localUserId, userId]);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      // Prevent the ESC from bubbling up
      event.preventDefault(); 
      dialogRef.current?.close();
      setLocalUserId(userId);
    }
  };

  const validateAccountAndUpdateState = useCallback((userId) => {
        validateAccount(userId)
            .then((res) => {
                if (res) {
                  setAccountValidationState(AccountValidationState.CORRECT_ID);
                } else {
                    setAccountValidationState(AccountValidationState.WRONG_ID);
                }
            })
            .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
    }, [setAccountValidationState]);

  return (
    <dialog 
      ref={dialogRef}
      onClick={handleBackdropClick}
      onKeyDown={handleKeyDown}
      className={styles.modalContainer}
    >
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{translate('enter-telegram-id')}</h2>
        <UserIdInput
            ref={inputRef}
            validationState={validationState}
            setAccountValidationState={setAccountValidationState}
            setUserId={setLocalUserId}
            validateAccountAndUpdateState={validateAccountAndUpdateState}
            userId={localUserId}
        />   
        <Button
            onClick={handleSave}
            title={translate("save")}
            isDisabled={AccountValidationState.CORRECT_ID !== validationState}
        />
      </div>
    </dialog>
  );
});

export default ChangeUserIdModal;
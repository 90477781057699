import './ui-kit.css';

export const Section = ({children}) => <div className={'App-Section'}>
    {children}
</div>

export const Title = ({children}) => <p className={'App-title'}>
    {children}
</p>;

export const Subtitle = ({children}) => <p className={'App-subtitle'}>
    {children}
</p>

export const RadioButton = ({isChecked}) => <div className={'App-radio-button-outside'}>
    <div className={`${isChecked && 'App-radio-button-active'}`}/>
</div>;

export const SelectableBox = ({identifier, isChecked, onClick, title, subtitle}) => <div
    key={identifier}
    className={`App-selectable-box ${isChecked && 'active'} ${identifier ==='vip' && 'vip'}`}
    onClick={() => onClick(identifier)}
>
    <RadioButton isChecked={isChecked}/>
    <span className={'App-selectable-box-title'}>
        {title} {identifier ==='vip'}
    </span>
    <Spacer/>
    <span dangerouslySetInnerHTML={{ __html: subtitle }} className={'App-selectable-box-subtitle'} />
</div>;

export const Spacer = () => <div className={'spacer'}/>;
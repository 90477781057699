function buildDescription() {
    return `${this.price.card}₽ <span>/</span> $${this.price.stripe}`;
}

const week = {
    price: {
        card: '199',
        stripe: '1.99',
    },
    fancyName: 'Недельная подписка',
    identifier: 'week',
};

week.getFancyPrice = buildDescription.bind(week);

const month = {
    price: {
        card: '399',
        stripe: '3.99',
    },
    fancyName: 'Месячная подписка',
    identifier: 'month',
};

month.getFancyPrice = buildDescription.bind(month);

const year = {
    price: {
        card: '1499',
        stripe: '14.99',
    },
    fancyName: 'Годовая подписка',
    identifier: 'year',
};

year.getFancyPrice = buildDescription.bind(year);

const yearDiscountNewYear = {
    price: {
        card: '699',
        stripe: '6.99',
    },
    fancyName: '🎁 Годовая подписка по новогодней акции',
    identifier: 'year_ny',
    // addForDiscounts: ['year_ny']
};

yearDiscountNewYear.getFancyPrice = buildDescription.bind(yearDiscountNewYear);

const vip = {
    price: {
        card: '6999',
        stripe: '69',
    },
    fancyName: 'VIP 💎',
    identifier: 'vip',
};

vip.getFancyPrice = buildDescription.bind(vip);

export const anonrubotPremiums = [week, month, year, vip];

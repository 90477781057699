import Project from "../constants/project";

export function getBotName() {
  switch (process.env.REACT_APP_PROJECT) {
    case Project.CHATBOT:
    case Project.CHATBOT_V2:
      return "@chatbot";
    case Project.ANONRUBOT:
      return "@anonrubot";
    default:
      console.error("Unknown project", process.env.REACT_APP_PROJECT);
      return "@anonrubot";
  }
}

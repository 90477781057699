export const validateAccount = async (uid) => {
    const res = await fetch(`${process.env.REACT_APP_TELEGRAM_BACKEND_URL}/premium/validate_uid/${uid}`, {
        headers: {
            'X-Authorization': process.env.REACT_APP_VALIDATE_API_KEY,
        }
    });
    if (res.status === 200) {
        const responseJson = await res.json();
        return responseJson.success;
    } else {
        return false;
    }
}

/*
    Returns full response which is {success: boolean, language: string}
*/
export const validateAccountV2 = async (uid) => {
    const res = await fetch(`${process.env.REACT_APP_TELEGRAM_BACKEND_URL}/premium/validate_uid/${uid}`, {
        headers: {
            'X-Authorization': process.env.REACT_APP_VALIDATE_API_KEY,
        }
    });
    if (res.status === 200) {
        return res.json();
    } else {
        return {success: false};
    }
}

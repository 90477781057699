import {useEffect, useState} from "react";
import {AccountValidationState} from "../constants/accountValidationStates";
import {validateAccountV2} from "./validate-account";
import {useI18n} from "../i18n/use-i18n";

export const useValidationState = (userId) => {
    const [accountValidationState, setAccountValidationState] = useState(AccountValidationState.NONE);
    const {setLanguage} = useI18n();

    useEffect(() => {
        if (userId.length > 5) {
            setAccountValidationState(AccountValidationState.VALIDATING);
            validateAccountV2(userId)
                .then(({success, language}) => {
                    if (success) {
                        setAccountValidationState(AccountValidationState.CORRECT_ID);
                        setLanguage(language);
                    } else {
                        setAccountValidationState(AccountValidationState.WRONG_ID);
                    }
                })
                .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
        }
    }, [userId, setLanguage]);

    return [accountValidationState, setAccountValidationState];
}

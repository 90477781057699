import AnonRuBot from "../apps/AnonRuBot";
import Project from "../constants/project";
import Chatbot from "../apps/Chatbot";
import ChatbotV2 from "../apps/ChatbotV2";


function App() {
    switch (process.env.REACT_APP_PROJECT) {
        case Project.CHATBOT:
            return <Chatbot/>;
        case Project.ANONRUBOT:
            return <AnonRuBot/>
        case Project.CHATBOT_V2:
            return <ChatbotV2/>;
        default:
            return <AnonRuBot/>
    }
}


export default App;

import { getBaseUrl } from "./get-api-url"

export const fetchProducts = async (userId)  => {
  try {
    const response = await fetch(`${getBaseUrl()}api/fetch-products/${userId}`)
    if (!response.ok) {
      console.error(`Fetch products api responded with bad status: ${response.status}`);  
      return [];
    }
    return response.json();
  } catch (e) {
    console.error(`Could not fetch products for ${userId}: ${e}`);
    return [];
  }
}
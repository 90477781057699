import "./App.scss";
import {useEffect, useState, useMemo, useCallback, useRef} from "react";
import {validateAccount} from "../util/validate-account";
import {
    AccountValidationState,
} from "../constants/accountValidationStates";
import {PERSIST_USER_ID_KEY} from "../constants/commonConstants";
import {Button} from "../components/Button";
import {useSearchParams} from "react-router-dom";
import {useValidationState} from "../util/use-validation-state";
import {Section, SelectableBox, Subtitle, Title} from "../components/ui-kit";
import UserIdInput from "../components/UserIdInput";
import {useI18n} from "../i18n/use-i18n";
import Head from "../components/Head";
import { getBotName } from "../util/get-bot-name";
import { getBotUrl } from "../util/get-bot-url";
import { fetchProducts } from "../util/fetch-products";
import { getGeneratePaymentLinkApiUrl } from "../util/get-api-url";
import ChangeUserIdModal from "../components/ChangeUserIdModal";

function ChatbotV2() {
    const [searchParams] = useSearchParams();
    const changeUserIdModalRef = useRef(null);
    const {translate, language} = useI18n();
    const [userId, setUserId] = useState('');
    const [emptyUserIdError, setEmptyUserIdError] = useState(false);
    const [validationState, setAccountValidationState] = useValidationState(userId);
    const [isFetching, setIsFetching] = useState(false);
    const [product, setProduct] = useState(null);
    const [method, setMethod] = useState(null);
    const [products, setProducts] = useState([]);
    const selectedProduct = useMemo(() => {
      return products.find((p) => p.name === product);
    }, [product, products]);
    const shouldShowProducts = useMemo(() => {
      return products.length > 0 && validationState === AccountValidationState.CORRECT_ID;
    }, [products, validationState]);
    const searchParamProduct = useMemo(() => searchParams.get('premium'), [searchParams]);
    const persistedUserId = useMemo(() => {
      return searchParams.get('userId') ?? localStorage.getItem(PERSIST_USER_ID_KEY);
    }, [searchParams]);
    const hasEnteredCorrectUserId = useMemo(() => {
      return AccountValidationState.CORRECT_ID === validationState;
    }, [validationState])

    const validateAccountAndUpdateState = useCallback((userId) => {
        validateAccount(userId)
            .then((res) => {
                if (res) {
                  setAccountValidationState(AccountValidationState.CORRECT_ID);
                } else {
                    setAccountValidationState(AccountValidationState.WRONG_ID);
                }
            })
            .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
    }, [setAccountValidationState]);

    useEffect(() => {
      if (hasEnteredCorrectUserId) {
        // If account was validated then fetch products
        setIsFetching(true);
        fetchProducts(userId)
        .then((response) => {
          if (response.success) {
            setProducts(response?.products ?? []);
          }
        })
        .finally(() => setIsFetching(false));
      }
    }, [hasEnteredCorrectUserId, userId]);

    useEffect(() => {
      // When fetched products set selected products and methods
      setProduct((currentProduct) => {
        // Use first product as fallback
        const fallbackProduct = products?.[0]?.name;
        if (!currentProduct) {
          // If currnet product is null then set selected first available product
          return fallbackProduct;
        }
        // Check if currently selected product exists
        const exists = products.findIndex((p) => p.name === currentProduct) !== -1;
        if (!exists) {
          // If it does not exist then use fallback
          return fallbackProduct;
        }
        // Don't do anything if currently selected product exist
        return currentProduct;
      });
      setMethod(products[0]?.routes?.[0]?.type);
    }, [products]);

    useEffect(() => {
        if (persistedUserId) {
            // Restore persisted uid on mount
            setUserId(persistedUserId);
        }
        if (searchParamProduct) {
            setProduct(searchParamProduct);
        }
    }, [persistedUserId, searchParamProduct]);

    const handleUserIdInputFocus = useCallback(() => {
      if (hasEnteredCorrectUserId) {
        // If user's id was verified then allow to change it only through modal
        changeUserIdModalRef.current?.showModal();
      }
    }, [hasEnteredCorrectUserId]);

    async function onFormSubmit(event) {
        event.preventDefault();

        if (!hasEnteredCorrectUserId) {
            return;
        }

        if (userId.length === 0) {
            setEmptyUserIdError(true);
            return;
        }

        if (!method || !product) {
          // If no method or no product was chosen show error
          //  Should never happen
          alert("Please select both a payment method and product before proceeding");
          return;
        }
        // If all checks were passed then persist uid
        localStorage.setItem(PERSIST_USER_ID_KEY, userId);
        setIsFetching(true);
        const urlParams = new URLSearchParams({
            userId,
            method,
            user_lang: language,
            premium: product,
        });
        fetch(getGeneratePaymentLinkApiUrl() + `?${urlParams.toString()}`)
            .then(async (response) => {
                if (response.status === 200) {
                    const {url} = await response.json();
                    window.location.href = url;
                } else {
                    alert(`Something went wrong while creating payment link: ${response.statusText} (${await response.text()})`);
                }
            })
            .catch(() => {
                alert('Something went wrong');
            })
            .finally(() => setIsFetching(false));
    }

    return (
        <>
        <ChangeUserIdModal 
          ref={changeUserIdModalRef}
          setUserId={setUserId}
          userId={userId}
        />
        <div className={'App-container'}>
            <Head/>
            <form onSubmit={onFormSubmit}>
                <h1 className={'App-header'}>
                    {translate("premium-in")} {getBotName()}
                </h1>
                <Section>
                    <Title>
                        {translate(hasEnteredCorrectUserId ? "enter-user-id" : "to-see-subscription-prices")}
                    </Title>
                    <Subtitle>
                        {translate("get-user-id-hint")} <a href={getBotUrl()} target="_blank" rel="noreferrer" className="App-link-blue">{getBotName()}</a>
                    </Subtitle>
                    <UserIdInput
                        validationState={validationState}
                        setAccountValidationState={setAccountValidationState}
                        setUserId={setUserId}
                        emptyUserIdError={emptyUserIdError}
                        validateAccountAndUpdateState={validateAccountAndUpdateState}
                        userId={userId}
                        onContainerClick={handleUserIdInputFocus}
                    />
                    {emptyUserIdError && !hasEnteredCorrectUserId &&
                        <label className={'App-input-invalid-label'}>{translate("enter-user-id")}</label>}
                </Section>
                {shouldShowProducts && <Section>
                    <Title>
                        {translate("choose-plan")}
                    </Title>
                    {products.map((item) => {
                        const {name, web_title_key, web_description_key } = item;
                        const isChecked = name === product;
                        return <SelectableBox
                            key={name}
                            identifier={name}
                            onClick={setProduct}
                            isChecked={isChecked}
                            title={web_title_key}
                            subtitle={web_description_key}
                        />;
                    })}
                </Section>}
                {shouldShowProducts && selectedProduct && <Section>
                    <Title>
                        {translate("choose-payment-method")}
                    </Title>
                    {selectedProduct.routes?.map((route) => {
                      const {type, web_title_key, web_description_key} = route;
                      const isChecked = type === method;
                      return <SelectableBox
                          key={type}
                          identifier={type}
                          onClick={setMethod}
                          isChecked={isChecked}
                          title={web_title_key}
                          subtitle={web_description_key}
                      />;
                    })}
                  </Section>}
                  <Section>
                    <Button
                        onClick={onFormSubmit}
                        title={translate("go-to-payment")}
                        isDisabled={!hasEnteredCorrectUserId || !method || !product}
                        isFetching={isFetching}
                    />
                    {validationState === AccountValidationState.WRONG_ID && <span className={'App-wrong-user-id-error'}>
                        {translate("user-id-does-not-exist")}
            </span>}
                </Section>
            </form>
        </div>
        </>
    );
}

export default ChatbotV2;

const CHATBOT = 'chatbot';
const CHATBOT_V2 = 'chatbotV2';
const ANONRUBOT = 'anonrubot';

const project = {
    CHATBOT,
    CHATBOT_V2,
    ANONRUBOT,
}

export default project;
import {Helmet} from "react-helmet";
import {useI18n} from "../i18n/use-i18n";
import Project from "../constants/project";
import { useLocation } from "react-router-dom";

const Head = () => {
    const {translate} = useI18n();
    const isChatBot = process.env.REACT_APP_PROJECT === Project.CHATBOT;
    const location = useLocation();
    
    // Create canonical URL by removing query parameters
    const canonicalUrl = `${window.location.origin}${location.pathname}`;

    return <Helmet>
        {/* Set title only for ChatBot because in AnonRuBot it's added via Cloudflare Snippet */}
        {isChatBot && <title>{translate("page-head-title")}</title>}
        <meta content={translate("page-head-meta-title")} property="og:title"/>
        <meta content={translate("page-head-description")}
              property="og:description"/>
        <meta content={translate("page-head-description")}
              property="description"/>
        <link rel="canonical" href={canonicalUrl} />
    </Helmet>;
}

export default Head;